:root {
  --space: 1.5em 0;
  --space: 2em 0;
}

body {
  height: 100vh;
  background-color: #132143;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  height: 100vh;
  background-color: #132143;
  color: #E6EAF3;
  text-align: center;
}

.dark-blue-fade-2 {
  background-color: #02091c80;
  color: black;
}

.dark-blue-fade {
  background-color: #132143a8;
  color: black;
}

.dark-blue {
  background-color: #132143;
  color: black;
}

.blue {
  background-color: #223F84;
  color: white;
}

.light-blue{
  background-color: #225AEE;
  color: black;
}

.dark-yellow{
  background-color: #FFBA00;
  color: black;
}

.yellow{
  background-color: #FFDB00;
  color: black;
}

.dark-red{
  background-color: #D60808;
  color: black;
}

.red{
  background-color: #FF3B29;
  color: black;
}

.eggshell{
  background-color: #E6EAF3;
  color: black;
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Site-content {
  flex: 1 0 auto;
  padding: var(--space) var(--space) 0;
  width: 100%;
}

.Site-content:after {
  content: '\00a0';
  display: block;
  margin-top: var(--space);
  height: 0;
  visibility: hidden;
}

Footer {
  margin-top: auto;
}