.carousel {
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: 24px;
    max-height: 500px;
    max-width: 700px;
}

.carousel img {
    max-height: 500px;
}